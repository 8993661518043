import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { useAddressCoverage } from "../../contexts/address-coverage-demo.context";
import { FormControl, FormField, FormItem, FormMessage } from "../ui/form";
import { CustomAddressInputWithContext as CustomAddressInput } from "./custom-address-input";

export const addressSchema = z.object({
  address: z.object(
    {
      street: z.string(),
      city: z.string(),
      postalCode: z.string(),
      country: z.string(),
      latitude: z.number(),
      longitude: z.number(),
    },
    {
      invalid_type_error: "Veuillez renseigner une adresse valide",
      required_error: "Veuillez renseigner votre adresse",
    }
  ),
});

export function AddressField() {
  const form = useFormContext<z.infer<typeof addressSchema>>();
  const {
    fetchCoverage,
    setPostalCode,
    error,
    loading,
    clearCoverage,
    displayUncoveredAddress,
    setAddressState,
  } = useAddressCoverage();

  const [address] = form.watch(["address"]);

  useEffect(() => {
    (async () => {
      if (!address) {
        // clearCoverage();
        return;
      }
      setPostalCode(address.postalCode);
      await fetchCoverage(address.postalCode);
    })();
  }, [address, clearCoverage, fetchCoverage, setPostalCode]);

  const addressMessage = useCallback(() => {
    if (!address) {
      return null;
    }
    if (error) {
      return (
        <div className="bg-red-100 p-2 rounded-md text-red-500">
          Une erreur est survenue, veuillez réessayer plus tard
        </div>
      );
    }
    if (loading) {
      return <p>Vérification de l'adresse...</p>;
    }
    if (displayUncoveredAddress) {
      return (
        <div className="bg-red-100 p-2 rounded-md text-red-500">
          Votre adresse n'est pas couverte par notre service.
        </div>
      );
    }
    return null;
  }, [address, displayUncoveredAddress, error, loading]);

  function clearAddressError() {
    form.clearErrors("address");
  }

  function setAddressError(message: string) {
    setAddressState("ERROR");
    form.setError("address", {
      type: "custom",
      message,
    });
  }

  return (
    <FormField
      control={form.control}
      name="address"
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <>
              <CustomAddressInput
                ref={field.ref}
                label="Entrez votre adresse résidentielle complète"
                onAddressChange={field.onChange}
                setAddressError={setAddressError}
                clearAddressError={clearAddressError}
              />
              {addressMessage()}
            </>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
