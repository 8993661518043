import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { setHours } from "date-fns";
import { useMutation } from "@tanstack/react-query";

import { useAddressCoverage } from "../contexts/address-coverage-demo.context";
import { Order } from "../types";
import routes from "../routes";
import { Form } from "./ui/form";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import { addressSchema } from "./fields/address-field";
import { ContactFields, contactSchema } from "./fields/contact-fields";
import { AppliancesField, appliancesSchema } from "./fields/appliances-field";
import {
  SmallAppliancesFormField,
  smallAppliancesSchema,
} from "./fields/small-appliances-field";
import { TermsFields, termsSchema } from "./fields/terms-field";
import { AddressRequestDialog } from "./address-request-dialog";
import { isWindowInIframe } from "../utils/functions";
import { createOrder } from "../api/order.api";
import { useTemporaryOrderContext } from "../contexts/temporary-order.context";
import config from "../utils/config";
import classNames from "classnames";
import SlotSelector from "./fields/slot-selector";
import { pickingSchema } from "./fields/picking-fields";

const submitButtonStyle = classNames(
  "w-full",
  "bg-[#F37547]",
  "text-white",
  "text-lg",
  "font-bold",
  "rounded-md",
  "p-2",
  "hover:bg-[#F37647DD]",
  "hover:shadow-lg"
);

const formSectionStyle = "bg-gray-100 p-2 rounded-md space-y-2 relative";

const formSchema = addressSchema
  .and(pickingSchema)
  .and(appliancesSchema)
  .and(smallAppliancesSchema)
  .and(contactSchema)
  .and(termsSchema);

type FormType = z.infer<typeof formSchema>;

export function CreateOrderForm() {
  const navigate = useNavigate();

  const [hasFocusedFirstError, setHasFocusedFirstError] = useState(false);

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      appliances: [],
      smallAppliances: {
        quantity: 0,
      },
      contact: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        hasElevator: false,
        floor: "RDC",
        doorCode: "",
      },
      terms: {
        isAccessible: false,
        isDonation: false,
        isPlacedInMyHome: false,
        isReliable: false,
      },
    },
    shouldFocusError: false,
  });

  const [address] = form.watch(["address"]);

  const mutation = useMutation({
    mutationFn: createOrder,
    onSuccess: async (response, order) => {
      if (
        response.hasAmericanFridge ||
        response.isApplianceNumberMoreThanThreshold
      ) {
        setTemporaryOrder(order);
        navigate(routes.temporarySummary);
      } else if (response.token) {
        navigate(routes.summary(response.token));
      }
    },
  });

  // Save order in a temporary context when the order has an American Fridge
  // to display a specific summary page
  const { setOrder: setTemporaryOrder } = useTemporaryOrderContext();

  const coverageCtx = useAddressCoverage();

  // Reset picking when address changes
  useEffect(() => {
    form.setValue("picking.date", undefined as any);
    form.setValue("picking.timeSlot", undefined as any);
  }, [address, form]);

  // Focus first error when form is submitted
  useEffect(() => {
    if (!hasFocusedFirstError && !form.formState.isValid) {
      const firstError = Object.keys(form.formState.errors)[0];
      const element = document.getElementById("form-" + firstError);
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
      setHasFocusedFirstError(true);
    }
  }, [form.formState, hasFocusedFirstError]);

  const SubmitButton = () => {
    return (
      <Button
        type="submit"
        className={submitButtonStyle}
        disabled={mutation.isPending}
      >
        Je valide ma collecte
      </Button>
    );
  };

  async function onSubmit(values: FormType) {
    if (values.smallAppliances.quantity > 0) {
      values.appliances.push({
        type: "small-appliance",
        label: "Petit électroménager",
        quantity: values.smallAppliances.quantity,
      });
    }
    const individualAppliances: Order["appliances"] = [];
    values.appliances.forEach((appliance: any) => {
      for (let i = 1; i <= appliance.quantity; i++) {
        individualAppliances.push({
          age: "unknown",
          state: "unknown",
          displayName:
            appliance.quantity > 1
              ? `${appliance.label} ${i}`
              : appliance.label,
          name: appliance.label,
        });
      }
    });
    const order: Order = {
      token: "",
      address: {
        street: values.address.street,
        postalCode: values.address.postalCode,
        city: values.address.city,
        country: "FR",
        latitude: values.address.latitude,
        longitude: values.address.longitude,
        coordinates: {
          lat: values.address.latitude,
          lng: values.address.longitude,
        },
      },
      meeting: {
        date: values.picking.date,
        slot: values.picking.timeSlot.id,
        interval: {
          start: setHours(
            new Date(values.picking.date),
            values.picking.timeSlot.startHour
          ).toISOString(),
          end: setHours(
            new Date(values.picking.date),
            values.picking.timeSlot.endHour
          ).toISOString(),
        },
      },
      appliances: individualAppliances,
      contact: {
        firstName: values.contact.firstName,
        lastName: values.contact.lastName,
        email: values.contact.email,
        phone: values.contact.phone,
        homeType: values.contact.homeType,
        elevator: values.contact.hasElevator,
        floor: values.contact.floor,
        doorCode: values.contact.doorCode,
      },
    };
    mutation.mutate(order);
  }

  // Show address request dialog when address is not covered
  // const showAddressRequestDialog = !coverageCtx.isCovered;

  // Show picking fields when address is covered
  const showPickingFields =
    !!address &&
    !coverageCtx.error &&
    !coverageCtx.loading &&
    coverageCtx.isCovered;

  const doDisplayAddressRequestDialog =
    !!address && coverageCtx.displayUncoveredAddress;
  return (
    <>
      {doDisplayAddressRequestDialog && (
        <AddressRequestDialog address={address} />
      )}
      <Form {...form}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setHasFocusedFirstError(false);
            form.handleSubmit(onSubmit)();
          }}
          className="space-y-4"
        >
          <SlotSelector
            classNames={{
              formSectionStyle,
              showPickingFields,
            }}
          />
          {/* Section Appliances */}
          <div className={formSectionStyle}>
            <Label>Vos appareils</Label>
            <div id="form-appliances">
              <AppliancesField />
            </div>
            {config.IS_FEATURE_SMALL_APPLIANCES_ENABLED && (
              <div id="form-smallApliances">
                <SmallAppliancesFormField />
              </div>
            )}
          </div>

          {/* Section Contact + Terns */}
          <div className={formSectionStyle}>
            <Label>Vos coordonnées</Label>
            <div id="form-contact">
              <ContactFields />
            </div>
            <div id="form-terms">
              <TermsFields />
            </div>
          </div>

          {/* Validation Error */}
          {form.formState.isSubmitted && !form.formState.isValid && (
            <div className="bg-red-100 p-2 rounded-md text-red-500">
              Veuillez vérifier que tous les champs sont correctement remplis.
            </div>
          )}

          {/* API Error */}
          {mutation.isError && (
            <div className="bg-red-100 p-2 rounded-md text-red-500">
              Une erreur est survenue lors de la création de la commande.
              Veuillez réessayer.
            </div>
          )}

          {/* Submit Button (fixed botton screen when in Iframe) */}
          {isWindowInIframe() ? (
            <div>
              <div className="h-10"></div>
              <div className="fixed bottom-0 left-0 right-0 px-2 py-4">
                <SubmitButton />
              </div>
            </div>
          ) : (
            <div className="flex justify-center">
              <SubmitButton />
            </div>
          )}
        </form>
      </Form>
    </>
  );
}
